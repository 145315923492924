import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var APIEndpoint = environment.APIEndpoint;
var InvoicesService = /** @class */ (function () {
    function InvoicesService(http) {
        this.http = http;
    }
    /*------get all invoices-------*/
    InvoicesService.prototype.getAllInvoices = function () {
        return this.http.get(APIEndpoint + "/parent/invoices");
    };
    /*------download invoices-------*/
    InvoicesService.prototype.downloadFile = function (period, invoiceType) {
        return this.http.get(APIEndpoint + "/parent/invoices/download?period=" + period + "&invoiceType=" + invoiceType, { responseType: 'blob' });
    };
    InvoicesService.ngInjectableDef = i0.defineInjectable({ factory: function InvoicesService_Factory() { return new InvoicesService(i0.inject(i1.HttpClient)); }, token: InvoicesService, providedIn: "root" });
    return InvoicesService;
}());
export { InvoicesService };
