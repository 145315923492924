import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserService } from './users.service';
import { StateService } from './state.service';
import { SmartTableService } from './smart-table.service';
import { UserActivityService } from './user-activity.service';
import { LayoutService } from './layout.service';
import { InvoicesService } from './invoices.service';
import { SubscriptioncalendarService } from './subscriptioncalendar.service';

const SERVICES = [
  UserService,
  StateService,
  SmartTableService,
  UserActivityService,
  LayoutService,
  InvoicesService,
  SubscriptioncalendarService
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
  declarations: [],
})
export class DataModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: DataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
