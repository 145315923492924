import { APP_BASE_HREF } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpRequest
} from "@angular/common/http";
import { CoreModule } from "./@core/core.module";
import { NoInternetComponent } from "./@theme/components/no-internet/no-internet.component";
import { UnsubscribeComponent } from "./@theme/components/unsubscribe/unsubscribe.component";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { ThemeModule } from "./@theme/theme.module";
import {
  NbAuthModule,
  NbPasswordAuthStrategy,
  NbAuthJWTInterceptor,
  NB_AUTH_TOKEN_INTERCEPTOR_FILTER
} from "@nebular/auth";
import { AuthGuard } from "./@core/data/auth.guard";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NbToastrService,NbLayoutModule, NbTooltipModule } from "@nebular/theme";
import { NbToastrModule, NbDialogModule, NbRadioModule, NbTabsetModule } from "@nebular/theme";
import { NgxAuthBlockComponent } from "./@theme/components/auth";
import { registerLocaleData } from "@angular/common";
import localeNl from "@angular/common/locales/nl";
import { environment } from "./../environments/environment";
import { NgxAuthJWTToken } from "./@core/data/NgxAuthJWTToken";
// the second parameter 'fr' is optional
registerLocaleData(localeNl, "nl");

const formSetting: any = {
  redirectDelay: 0,
  showMessages: {
    success: true
  }
};

const APIEndpoint = environment.APIEndpoint;

export function filterInterceptorRequest(req: HttpRequest<any>) {
  return [
    APIEndpoint + "/validate-login",
    APIEndpoint + "/parent/parentlocations"
  ].some(url => req.url.includes(url));
}

@NgModule({
  declarations: [AppComponent, NoInternetComponent, NgxAuthBlockComponent],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbLayoutModule,
    ThemeModule.forRoot(),
    CoreModule.forRoot(),
    NgbModule.forRoot(),
    NbToastrModule.forRoot(),
    NbDialogModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: "email",
          token: {
            class: NgxAuthJWTToken,
            key: "token" // this parameter tells where to look for the token
          },
          baseEndpoint: APIEndpoint,
          login: {
            endpoint: "/validate-login",
            method: "post",
            requireValidToken: true,
            redirect: {
              success: "/home/dashboard",
              failure: "/auth/login"
            },
            defaultErrors: [
              "Login / Email-combinatie klopt niet, probeer opnieuw."
            ],
            defaultMessages: ["U bent succesvol ingelogd."]
          },
          register: {
            redirect: {
              success: "/auth/login",
              failure: "/auth/register"
            },
            defaultErrors: ["Something went wrong, please try again."],
            defaultMessages: ["You have been successfully registered."]
          },
          requestPass: {
            redirect: {
              success: "",
              failure: ""
            },
            defaultErrors: ["Something went wrong, please try again."],
            defaultMessages: [
              "Reset password instructions have been sent to your email."
            ]
          },
          resetPass: {
            redirect: {
              success: "",
              failure: ""
            },
            resetPasswordTokenKey: "token",
            defaultErrors: ["Something went wrong, please try again."],
            defaultMessages: ["Wachtwoord is succesvol gewijzigd."]
          },
          refreshToken: {
            endpoint: "/refresh-token",
            method: "post",
            requireValidToken: false,
            redirect: {
              success: "/home/dashboard",
              failure: "/auth/login"
            },
            defaultErrors: [
              "Er is iets mis gegaan, probeer het alstublieft nogmaals."
            ],
            defaultMessages: ["Uw token is succesvol vernieuwd."]
          },
          logout: {
            endpoint: "/logout",
            method: "post",
            redirect: {
              success: "/auth/login",
              failure: "/auth/login"
            },
            defaultErrors: [
              "Er is iets mis gegaan, probeer het alstublieft nogmaals."
            ],
            defaultMessages: ["U bent succesvol uitgelogd."]
          }
        })
      ],
      forms: {
        login: formSetting,
        register: formSetting,
        requestPassword: formSetting,
        resetPassword: formSetting,
        logout: {
          redirectDelay: 0
        }
      }
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthGuard,
    { provide: APP_BASE_HREF, useValue: "/" },
    { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
    {
      provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
      useValue: filterInterceptorRequest
    },
    { provide: LOCALE_ID, useValue: "nl-BE" }
  ]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeNl); 
  }
}
