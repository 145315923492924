import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IResponse } from './response';
import { environment } from '../../../environments/environment';

const APIEndpoint = environment.APIEndpoint;

@Injectable({
  providedIn: 'root'
})

export class InvoicesService{

    constructor(private http: HttpClient) { }
    
    /*------get all invoices-------*/
    getAllInvoices(): Observable<IResponse> {
         return this.http.get<IResponse>(APIEndpoint + "/parent/invoices");
    }

    /*------download invoices-------*/
    downloadFile(period, invoiceType): Observable<any> {
        return this.http.get(APIEndpoint + "/parent/invoices/download?period=" + period + "&invoiceType=" + invoiceType, { responseType: 'blob' });
   }
}
