import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IResponse } from './response';

const APIEndpoint = environment.APIEndpoint;

@Injectable({
  providedIn: 'root'
})
export class SubscriptioncalendarService {

    constructor(private http: HttpClient) { }

    /*------get all invoices-------*/
    getChildrenSubscriptionsForCalendar(): Observable<IResponse> {
        return this.http.get<IResponse>(APIEndpoint + "/parent/childSubscriptionsCalendar");
    }
  
}
