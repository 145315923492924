import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var APIEndpoint = environment.APIEndpoint;
var SubscriptioncalendarService = /** @class */ (function () {
    function SubscriptioncalendarService(http) {
        this.http = http;
    }
    /*------get all invoices-------*/
    SubscriptioncalendarService.prototype.getChildrenSubscriptionsForCalendar = function () {
        return this.http.get(APIEndpoint + "/parent/childSubscriptionsCalendar");
    };
    SubscriptioncalendarService.ngInjectableDef = i0.defineInjectable({ factory: function SubscriptioncalendarService_Factory() { return new SubscriptioncalendarService(i0.inject(i1.HttpClient)); }, token: SubscriptioncalendarService, providedIn: "root" });
    return SubscriptioncalendarService;
}());
export { SubscriptioncalendarService };
